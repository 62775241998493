
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getRoutingListing, deleteConfiguration } from "@/store/api/devices";
import RoutingConfig from "@/views/routing/RoutingConfig.vue";

interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    RoutingConfig,
  },
  setup(props) {
    const customers = ref<any[]>([]);
    const obj = ref<any>();
    const customerData = ref<any[]>([]);
    const companyObject = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const searchValue = ref<any>("");
    const headerConfig = ref([
      {
        name: "ID",
        key: "id",
      },
      {
        name: "Customer Name",
        key: "name",
      },
      {
        name: "Optimal Routing",
        key: "optimal_routing",
      },
      {
        name: "Route Time",
        key: "maximum_route_time",
      },
      {
        name: "Slack Time",
        key: "maximum_slack_time",
      },
      {
        name: "Dynamic Route",
        key: "is_dynamic_route",
      },
      {
        name: "Enabled / Disabled",
        key: "routing_enabled",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const permissions = ref<any>("");

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      loadData();
      setCurrentPageBreadcrumbs("Routing", [""]);
    });

    const loadData = async () => {
      loading.value = true;
      let value = await getRoutingListing(pagination.value);
      customers.value = value.routingConfig;
      customerData.value = customers.value;
      total.value = value.total_number_of_records;
      loading.value = false;
    };
    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const deltCompany = async (id) => {
      Swal.fire({
        title: "Are you sure you want to Delete this Company?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteConfiguration(id);
          console.log(resp);
          loadData();
          Swal.fire({
            icon: "success",
            title: "Company has been Deleted",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    };

    const getCompanyDetail = (val) => {
      console.log(val);
      companyObject.value = val;
      obj.value = val;
    };

    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    return {
      headerConfig,
      customers,
      obj,
      total,
      loading,
      deltCompany,
      search,
      pagination,
      cardData,
      currentChange,
      getCompanyDetail,
      searchItems,
      getCustomerName,
      customerData,
      itemsPerPageChange,
      onCustomerChange,
      getRoutingListing,
      searchValue,
      permissions,
      companyObject,
    };
  },
});
